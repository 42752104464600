import http from "./request";

export const adminGoodsList = (params) => {
  return http.get("adminGood/listGood", { params: params });
};
export const adminCategoryList = (params) => {
  return http.get("adminGood/getCategory", { params: params });
};
export const operateProductService = (params) => {
  return http.post("operationProblem/save_service_management", params);
};
export const opDetail = (params) => {
  return http.get("operationProblem/opDetail", { params: params });
};
export const goodsPackageList = (params) => {
  return http.get("stuOp/stuGoodsPackageList", { params: params });
};
export const saveGoodsPackage = () => {
  return http.post("stuOp/stuServiceManagementSubmit");
};
export const goodsMealList = () => {
  return http.get("stuOp/smStuGoodsList");
};
export const saveGoodsMeal = (params) => {
  return http.post("stuOp/stuSaveGoodsPackage", params);
};
export const delGoodsMeal = (params) => {
  return http.post("stuOp/stuGoodsPackageDel", params);
};
export const goodsMealInfo = (params) => {
  return http.get("stuOp/stuGoodsPackageInfo", { params: params });
};
export const hotGoodsList = (params) => {
  return http.get("stuOp/serviceManagementGoodsList", { params: params });
};
export const serviceManagementData = (params) => {
  return http.get("teacherop/serviceManagementData", { params: params });
};
export const hotGoodsDetails = (params) => {
  return http.get("stuOp/serviceManagementGoodsInfo", { params: params });
};
